import React, { useState } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
//import { ContactForm } from "../forms";
import UserForm from "../forms/UserForm";
import Spinner from "../Spinner";

const ContactPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
}) => {
  let [spinner, setSpinner] = useState(false);


  const managespinner = (spinner) => {
    setSpinner(spinner);
  };

  console.log(spinner);

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content has-text-centered">
            <section className="section">
              <div className="columns">
                <div className="column is-12">
                  <div className="content">
                    <div className="columns is-multiline">
                      <div className="column is-6">
                        <br />
                        <h3>Get in Touch</h3>
                        <p>We're looking forward to working with you.</p>
                        <br />
                        <a href="tel:‪5127109557‬">
                          <h2>
                            <span className="orangeText">(512) 710-9557</span>
                          </h2>
                        </a>
                        <hr />
                        <a href="https://www.facebook.com/idealOpsSFDCAdobe/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-labelledby="socialTitle socialDesc"
                            width="54"
                            height="54"
                            role="img"
                            viewBox="0 0 24 24"
                          >
                            <title id="socialTitle">
                              Ideal Ops - Salesforce. Marketo. Facebook Link.
                            </title>
                            <desc id="socialDesc">
                              Ideal Ops Consulting Salesforce Professional
                              Services Facebook Link.
                            </desc>
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
                          </svg>
                        </a>
                        <a href="https://twitter.com/OpsSfdc">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-labelledby="socialTitle socialDesc"
                            width="54"
                            height="54"
                            role="img"
                            viewBox="0 0 24 24"
                          >
                            <title id="socialTitle">
                              Ideal Ops - Salesforce. Marketo. Twitter feed.
                            </title>
                            <desc id="socialDesc">
                              Ideal Ops Consulting Salesforce Professional
                              Services Twitter Feed
                            </desc>
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z" />
                          </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/ideal-ops/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-labelledby="socialTitle socialDesc"
                            width="54"
                            height="54"
                            role="img"
                            viewBox="0 0 24 24"
                          >
                            <title id="socialTitle">
                              Ideal Ops - Salesforce. Marketo. LinkedIn.
                            </title>
                            <desc id="socialDesc">
                              Ideal Ops Consulting Salesforce Professional
                              Services LinkedIn
                            </desc>
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z" />
                          </svg>
                        </a>
                        <a href="https://www.youtube.com/channel/UCqF72iLgWtJgr9wvUudcA-A">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="54"
                            height="54"
                            role="img"
                            viewBox="0 0 24 24"
                          >
                            <title id="socialTitle">
                              Ideal Ops - Salesforce. Marketo. Youtube Feed.
                            </title>
                            <desc id="socialDesc">
                              Ideal Ops Consulting Salesforce Professional
                              Services Youtube Page
                            </desc>
                            <path d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
                          </svg>
                        </a>
                      </div>
                      <div className="column is-6">
                        <UserForm toggleSpinner={managespinner} />
                        {spinner ? <Spinner /> : "" }


                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
