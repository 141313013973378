import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ContactPageTemplate from "../../components/ContactPageTemplate";
import Layout from "../../components/Layout";
import logo from "../../assets/img/idealops.png";
import SE0 from "../../components/SEO";



const ContactPage = ({ data }) => {
  // const { frontmatter } = data.markdownRemark;
  return (
    <Layout>

        <SE0
        title={data.allContentfulContactUs.edges[0].node.title}
        meta_title={data.allContentfulContactUs.edges[0].node.metaTitle}
        meta_desc={ data.allContentfulContactUs.edges[0].node.metaDescription}
        contentType="website"
        cover = {logo}
        directory = "/contact/"
        slug =""
        

      />
      <ContactPageTemplate
        title={data.allContentfulContactUs.edges[0].node.title}
        subtitle={data.allContentfulContactUs.edges[0].node.subtitle}
        meta_title={data.allContentfulContactUs.edges[0].node.metaTitle}
        meta_description={
          data.allContentfulContactUs.edges[0].node.metaDescription
        }
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const contactPageQueryLive = graphql`
  query ContactUsQueryLive {
    allContentfulContactUs {
      edges {
        node {
          subtitle
          metaTitle
          metaDescription
          title
        }
      }
    }
  }
`;
