import React, { useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import { navigate } from "gatsby";
const URL = "https://tw7itnmgfb.execute-api.us-east-1.amazonaws.com/production";
const required = "This field is required";
let errorsCap = "";



export default ({toggleSpinner}) => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm();


  const onSubmit = async (data) => {
    toggleSpinner(true)
    
    try {
      await fetch(URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {});
      navigate("/contact/success", { state: { name: data.name } });

      reset();
    } catch (errors) {
      errorsCap = errors;
      toggleSpinner(false)


      setError("submit", "submitError", `Error: ${errors.message}`);
    }
  };

  const showThankYou = (
    <div className="msg-confirm">
      <p>Awesome! Your message was sent.</p>
      <button type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </button>
    </div>
  );
  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <div className="field">
        <label className="label" htmlFor="name">
          <div className="control">
            <h4>Name</h4>
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              placeholder="Enter your name"
              ref={register({
                required: "Name is required.",
              })}
              disabled={isSubmitting}
            />
          </div>
        </label>
        {errors.name && (
          <small className="has-text-danger">{errors.name.message}</small>
        )}
      </div>
      <div className="field">
        <label className="label" htmlFor="name">
          <div className="control">
            <h4>Email</h4>
            <input
              className="input"
              type="email"
              name="email"
              id="email"
              placeholder="your@email.address"
              ref={register({
                required: "Email Address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              })}
              disabled={isSubmitting}
            />
          </div>
        </label>
        {errors.email && (
          <small className="has-text-danger">{errors.email.message}</small>
        )}
      </div>
      <div className="field">
        <label className="label" htmlFor="name">
          <div className="control">
            <h4>Phone</h4>
            <input
              className="input"
              type="phone"
              name="phone"
              id="phone"
              placeholder="(555) 555-5555"
              ref={register}
              disabled={isSubmitting}
            />
          </div>
        </label>
      </div>
      <div className="field">
        <div className="control">
          <h4>Message</h4>
          <textarea
            className="textarea"
            ref={register({
              required: "A message entry is required.",
            })}
            name="desc"
            id="desc"
            rows="3"
            placeholder="Let us know your thoughts"
            disabled={isSubmitting}
          />
        </div>
        {errors.question && (
          <small className="has-text-danger">{errors.question.message}</small>
        )}
      </div>
      <div className="field is-grouped is-pulled-left">
        <div className="control">
        <label className="checkbox">
          <input ref={register} type="checkbox" id="talktosales" name="talktosales" /> Talk to Sales?
          </label>
          </div>
          <div className="control">

          <label className="checkbox">
          <input ref={register} type="checkbox" id="newsletter" name="newsletter"/> Sign up for our newsletter.
          </label>
          </div>
      </div>
      <div className="field is-grouped is-pulled-right">
        <div className="control">
          <button
            type="submit"
            disabled={isSubmitting}
            className="button is-primary is-outlined"
          >
            Submit
          </button>
        </div>
        {errorsCap && (
          <small className="has-text-danger">
            The server is down. Please try again later.
          </small>
        )}
      </div>
    </form>
  );

  return (
    <div className="page contact-page">
      <div className="form-side">{showForm}</div>
    </div>
  );
};
